<template>
  <div>
    
    <Table :title="title" :fields="headers" :url="url">
      <template #cell(debtor)="data">
        
        <b-link v-if="data.item && data.item.debtor && data.item.debtor.name">
          {{ data.item.debtor.name }}
        </b-link>
      </template>
    </Table>
    
  </div>
</template>
<script>
import Table from "../common/Table.vue";

export default {
  name: "Invoices",

  components: {
    Table,
  },
  data() {
    return {
      headers: [
        {
          key: "id",
          text: "id",
          label: "Id",
          sortable: true,
          value: "id",
        },
        {
          key: "title",
          text: "title",
          label: "Document",
          sortable: true,
          value: "title",
        },
        { key: "ext", label: "Ext", sortable: true, value: "ext", text: "ext" },
        {
          key: "company",
          label: "Company",
          sortable: true,
          value: "company",
          text: "company",
        },
      ],
      title: "Invoices",
      url: "/invoice/admin/list",
    };
  },
  methods: {},
};
</script>
